
import './App.css';

import { Routes, Route, useLocation } from "react-router-dom";

//Importo los componentes
import ContactMe from './views/ContactMe';
import About from './views/About';
import Leanding from './views/Leanding';
import NotFound from './views/NotFound';
import Project from './views/Project';
import TechKills from './views/TechKills';
import Nav from './components/Nav';
import Detail from './components/Detail';

function App() {

  const { pathname } = useLocation();

  //This function checks which route you are on to display the Nav
  const verificarRutas = () => {
    switch (pathname) {
      case "/": return true
      case "/about": return true
      case "/projects": return true
      case "/contact-me": return true
      case "/tech-kills": return true
      case "/detail/:id": return false
      default: return false
    }
  }


  return (
    <div className="App">
      {verificarRutas() && <Nav />}

      <Routes>

        <Route path="/" element={< Leanding />} />
        <Route path="/about" element={< About />} />
        <Route path="/projects" element={< Project />} />
        <Route path="/contact-me" element={< ContactMe />} />
        <Route path="/tech-kills" element={< TechKills />} />
        <Route path="/detail/:id" element={< Detail />} />
        <Route path='*' element={<NotFound />} />

      </Routes>
    </div>
  );
}

export default App;
