import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from '../assets/styles/components/Card.module.css';
const Card = ({ id, name, picture }) => {
	const navigate=useNavigate();
	return (
		<div className={styles.content} onClick={()=>{navigate(`/detail/${id}`);}}>
			<div className={styles.contentImage}>
				<img src={picture} alt="picture of project" title="Click on name" />
			</div>
			<h3 className={styles.name}>
				{name}
			</h3>
		</div>
	);
};

export default Card;
