import React from 'react';
import styles from '../assets/styles/components/views/About.module.css';
import cv from '../utils/CvMiguelFernandez.pdf';
import Footer from '../components/Footer';
const Home = () => {
	return (
		<div className={styles.container}>
			<main className={styles.contentMain}>
				<section className={styles.contentProfile}>
					<img
						src="https://res.cloudinary.com/dgp4xwknu/image/upload/v1714073215/Portfolio%20Miguel%20Fernandez/mtk2nvrkd3uhmlx4kioc.jpg"
						alt="perfil"
					/>
				</section>
				<section className={styles.contentText}>
					<p>
					Hello, my name is Miguel is a pleasure to visit my portfolio, you can follow me on github and linkedin, I am dedicated to Web Development, at first I did it in a self-taught way, learning HTML5, CSS3 and JS and also did projects with those languages, in search of constant professional growth I applied to Henry's bootcamp with which I learned a lot of soft skills and technologies, which I am currently using in my individual and group projects, and the truth is I am very happy with my progress. I still have a lot to learn and I'm willing to put all of me to do it, to be able to make a living from what I like and fascinates me.

					</p>
					<div className={styles.contentButton}>
						<img
							src="https://icongr.am/entypo/download.svg?size=147&color=ffffff"
							alt=""
							className={styles.imageDownload}
						/>
						<button>
							<a href={cv} download="CV Miguel Fernandez.pdf">
								Download CV
							</a>
						</button>
					</div>
				</section>
			</main>
			<Footer />
		</div>
	);
};

export default Home;
