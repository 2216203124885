import React from 'react';
import styles from '../assets/styles/components/views/NotFound.module.css';
const NotFound = () => {
	return (
		<div className={styles.container}>
			<main className={styles.content}>
				<h1 className={styles.title}>Page not found</h1>
				<div className={styles.contentLogoMF}>
					<img
						className={styles.contentImage}
						src="https://res.cloudinary.com/dgp4xwknu/image/upload/v1687300265/Portfolio%20Miguel%20Fernandez/Logo_ccu1w4.webp"
						alt="logo oficial"
						title="logo oficial"
					/>
				</div>
				<h3 className={styles.titleSecondary}>Error 404</h3>
			</main>
		</div>
	);
};

export default NotFound;
