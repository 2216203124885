import React from 'react';
import Typerwriter from 'typewriter-effect';
import logoGithub from '../assets/img/Github_logo_blanco.png';
import logoLinkedin from '../assets/img/Linkedin_color.png';
import logoInstagram from '../assets/img/instagram.png';
import logoYoutube from '../assets/img/youtube.png';
import styles from '../assets/styles/components/views/Leanding.module.css';
const Leanding = () => {
	return (
		<div className={styles.container}>
			<main className={styles.content}>
				<section className={styles.contentLogos}>
					<div className={styles.contentLogoMF}>
						<img
							className={styles.contentImage}
							src="https://res.cloudinary.com/dgp4xwknu/image/upload/v1687300265/Portfolio%20Miguel%20Fernandez/Logo_ccu1w4.webp"
							alt="logo oficial"
							title="logo oficial"
						/>
					</div>
					<article className={styles.profiles}>
						<div className={styles.contentLogoProfile}>
							<a href="https://github.com/Fer-Mig-Agus" target="_blank">
								<img src={logoGithub} alt="github" title="profile github" />
							</a>
						</div>
						<div className={styles.contentLogoProfile}>
							<a
								href="https://www.linkedin.com/in/miguel-agustin-fernandez-aa1596248/"
								target="_blank"
							>
								<img
									src={logoLinkedin}
									alt="linkedin"
									title="profile linkedin"
								/>
							</a>
						</div>
						<div className={styles.contentLogoProfile}>
							<a href="https://www.youtube.com/channel/UC4r9fia857HbFKswu9YqaCg" target="_blank">
								<img src={logoYoutube} alt="youtube" title="profile youtube" />
							</a>
						</div>
						<div className={styles.contentLogoProfile}>
							<a href="https://www.instagram.com/mf_dev_official/" target="_blank">
								<img src={logoInstagram} alt="instagram" title="profile instagram" />
							</a>
						</div>
					</article>
				</section>
				<section className={styles.contentText}>
					<div className={styles.saludation}>
						<Typerwriter
							options={{
								strings: [
									'Hello my name is Miguel',
									'Hola mi nombre es Miguel',
								],
								autoStart: true,
								loop: true,
							}}
						/>
					</div>
					<div className={styles.profession}>
						<Typerwriter
							options={{
								strings: [
									'I am Full Stack Developer',
									'Soy desarrollador Full Stack',
								],
								autoStart: true,
								loop: true,
							}}
						/>
					</div>
				</section>
			</main>
		</div>
	);
};

export default Leanding;
