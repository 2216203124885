import React from 'react';

import styles from '../assets/styles/components/views/TechKill.module.css';
import Footer from '../components/Footer';
import logoGithub from '../assets/img/Github_logo_blanco.png';
import redux from '../assets/img/Redux .webp';
import cloudinary from '../assets/img/Cloudinary full hd .webp';
const TechKills = () => {
	return (
		<div className={styles.container}>
			<main className={styles.content}>
				<h1 className={styles.title}>Tech Skills</h1>
				<div className={styles.contentTecnologys}>
					<div className={styles.contentTechKills}>
						<h2 className={styles.titleSecondary}>Programming languages</h2>

						<div className={styles.boxTechKills}>
							<div className={styles.contentLogo}>
								<a
									href="https://es.wikipedia.org/wiki/C_(lenguaje_de_programaci%C3%B3n)"
									target="_blank"
								>
									<img
										src="https://icongr.am/devicon/c-original.svg?size=128&color=currentColor"
										alt="C"
										title="C"
									/>
								</a>
							</div>
							<div className={styles.contentLogo}>
								<a
									href="https://es.wikipedia.org/wiki/Java_(lenguaje_de_programaci%C3%B3n)"
									target="_blank"
								>
									<img
										src="https://icongr.am/devicon/java-original.svg?size=148&color=ffffff"
										alt="Java"
										title="Java"
									/>
								</a>
							</div>
							<div className={styles.contentLogo}>
								<a
									href="https://es.wikipedia.org/wiki/JavaScript"
									target="_blank"
								>
									<img
										src="https://icongr.am/devicon/javascript-original.svg?size=148&color=ffffff"
										alt="JS"
										title="Javascript"
									/>
								</a>
							</div>
							<div className={styles.contentLogo}>
								<a href="https://es.wikipedia.org/wiki/Python" target="_blank">
									<img
										src="https://icongr.am/devicon/python-original.svg?size=148&color=ffffff"
										alt="Python"
										title="Python"
									/>
								</a>
							</div>
						</div>
					</div>

					<div className={styles.contentTechKills}>
						<h2 className={styles.titleSecondary}>Back End</h2>
						<div className={styles.boxTechKills}>
							<div className={styles.contentLogo}>
								<a
									href="https://kinsta.com/es/base-de-conocimiento/que-es-express/"
									target="_blank"
								>
									<img
										src="https://icongr.am/devicon/express-original.svg?size=128&color=ffffff"
										alt="Express"
										title="Express"
									/>
								</a>
							</div>
							<div className={styles.contentLogo}>
								<a href="https://nodejs.org/es/about" target="_blank">
									<img
										src="https://icongr.am/devicon/nodejs-original.svg?size=148&color=ffffff"
										alt="Node js"
										title="Node js"
									/>
								</a>
							</div>
							<div className={styles.contentLogo}>
								<a
									href="https://es.wikipedia.org/wiki/PostgreSQL"
									target="_blank"
								>
									<img
										src="https://icongr.am/devicon/postgresql-original-wordmark.svg?size=148&color=ffffff"
										alt="PostgreSQL"
										title="PostgreSQL"
									/>
								</a>
							</div>
							<div className={styles.contentLogo}>
								<a
									href="https://medium.com/@khriztianmoreno/sequelize-101-5810bfa1332f"
									target="_blank"
								>
									<img
										src="https://icongr.am/devicon/sequelize-original.svg?size=148&color=ffffff"
										alt="Sequelize"
										title="Sequelize"
									/>
								</a>
							</div>
							<div className={styles.contentLogo}>
								<a
									href="https://blog.hubspot.es/website/que-es-mysql"
									target="_blank"
								>
									<img
										src="https://icongr.am/devicon/mysql-original-wordmark.svg?size=148&color=0000ff"
										alt="MySQL"
										title="MySQL"
									/>
								</a>
							</div>
						</div>
					</div>

					<div className={styles.contentTechKills}>
						<h2 className={styles.titleSecondary}>Front End</h2>
						<div className={styles.boxTechKills}>
							<div className={styles.contentLogo}>
								<a
									href="http://blog.enriqueoriol.com/2018/08/que-es-redux.html"
									target="_blank"
								>
									<img src={redux} alt="Redux" title="Redux" />
								</a>
							</div>
							<div className={styles.contentLogo}>
								<a
									href="https://blog.hubspot.es/website/que-es-css"
									target="_blank"
								>
									<img
										src="https://icongr.am/devicon/css3-original.svg?size=128&color=currentColor"
										alt="CSS"
										title="CSS3"
									/>
								</a>
							</div>
							<div className={styles.contentLogo}>
								<a
									href="https://desarrolloweb.com/articulos/que-es-html.html"
									target="_blank"
								>
									<img
										src="https://icongr.am/devicon/html5-original.svg?size=148&color=ffffff"
										alt="HTML"
										title="HTML5"
									/>
								</a>
							</div>
							<div className={styles.contentLogo}>
								<a
									href="https://www.hostinger.com.ar/tutoriales/que-es-react"
									target="_blank"
								>
									<img
										src="https://icongr.am/devicon/react-original.svg?size=148&color=ffffff"
										alt="React"
										title="React js"
									/>
								</a>
							</div>
						</div>
					</div>

					<div className={styles.contentTechKills}>
						<h2 className={styles.titleSecondary}>Versionalizers</h2>
						<div className={styles.boxTechKills}>
							<div className={styles.contentLogo}>
								<a
									href="https://www.xataka.com/basics/que-github-que-que-le-ofrece-a-desarrolladores"
									target="_blank"
								>
									<img src={logoGithub} alt="Github" title="Github" />
								</a>
							</div>
							<div className={styles.contentLogo}>
								<a
									href="https://www.atlassian.com/es/git/tutorials/what-is-git"
									target="_blank"
								>
									<img
										src="https://icongr.am/devicon/git-original.svg?size=128&color=currentColor"
										alt="Git"
										title="Git"
									/>
								</a>
							</div>
						</div>
					</div>

					<div className={styles.contentTechKills}>
						<h2 className={styles.titleSecondary}>Others</h2>
						<div className={styles.boxTechKills}>
							<div className={styles.contentLogo}>
								<a
									href="https://vivevirtual.es/inteligencia-artificial/cloudinary-la-api-de-imagen-y-video-mas-potentes/"
									target="_blank"
								>
									<img src={cloudinary} alt="Cloudinary" title="Cloudinary" />
								</a>
							</div>
							<div className={styles.contentLogo}>
								<a
									href="https://es.wikipedia.org/wiki/Adobe_Photoshop"
									target="_blank"
								>
									<img
										src="https://icongr.am/devicon/photoshop-line.svg?size=148&color=0000ff"
										alt="Photoshop"
										title="Photoshop"
									/>
								</a>
							</div>
							<div className={styles.contentLogo}>
								<a
									href="https://www.geeknetic.es/Linux/que-es-y-para-que-sirve"
									target="_blank"
								>
									<img
										src="https://icongr.am/devicon/linux-plain.svg?size=148&color=ffff00"
										alt="Linux"
										title="Linux"
									/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</main>
			<Footer />
		</div>
	);
};

export default TechKills;
