

export const validateFields = (form, stateError) => {
    const error = { ...stateError };

    if (!form.name) error.name = "Complete the field";
    else if (form.name === "") error.name = "Complete the field";
    else if (!isNaN(form.name)) error.name = "Name cannot contain numbers";
    else error.name = "";

    if (!form.last_name) error.last_name = "Complete the field";
    else if (form.last_name === "") error.last_name = "Complete the field";
    else if (!isNaN(form.last_name)) error.last_name = "Last name cannot contain numbers";
    else error.last_name = "";


    if (!form.phone) error.phone = "Complete the field";
    else if (form.phone === "") error.phone = "Complete the field";
    else if (isNaN(form.phone)) error.phone = "The phone must be a number";
    else if (form.phone.includes("-")) error.phone = "The number cannot be negative";
    else error.phone = "";

    if (!form.email) error.email = "Complete the field";
    else if (form.email === "") error.email = "Complete the field";
    else if (! /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{3})+$/.test(form.email)) error.email = "No email structure";
    else if (form.email.length >= 35) error.email = "Exceeds the allowed characters";
    else error.email = "";

    if (!form.message) error.message = "Complete the field";
    else if (form.message === "") error.message = "Complete the field";
    else error.message = "";

    return error;

}


export const validateBeforeSubmit = (form) => {
    if (!form.name || form.name === "" || !isNaN(form.name)) return false
    if (!form.last_name || form.last_name === "" || !isNaN(form.last_name)) return false
    if (!form.phone || form.phone === "" || isNaN(form.phone) || form.phone.includes("-")) return false
    if (!form.message || form.message === "") return false
    if (!form.email || form.email === "" || ! /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{3})+$/.test(form.email)) return false
    return true
}