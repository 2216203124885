import React from 'react';
import { useState } from 'react';
import { validateFields, validateBeforeSubmit } from '../utils/validateFields';
import styles from '../assets/styles/components/views/ContacteMe.module.css';
import swal from 'sweetalert';
import Footer from '../components/Footer';
//Email js
import { useRef } from 'react';
import emailjs from '@emailjs/browser';

const ContacteMe = () => {
	const formulario = useRef();
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState({
		name: '',
		last_name: '',
		phone: '',
		email: '',
		message: '',
	});

	const [error, setError] = useState({
		name: '',
		last_name: '',
		phone: '',
		email: '',
		message: '',
	});

	const handleChangeInput = (event) => {
		const property = event.target.name;
		const value = event.target.value;

		setForm({ ...form, [property]: value });
		setError(validateFields({ ...form, [property]: value }, error));
	};

	const handleSubmitForm = (event) => {
		event.preventDefault();

		if (!validateBeforeSubmit(form)) {
			return swal({
				title: 'Error!',
				text: 'All fields are required',
				icon: 'error',
				buttons: 'Understood',
			});
		}
		setLoading(true);
		sendEmail();
	};

	const sendEmail = () => {
		emailjs
			.sendForm(
				'service_fjmqqjt',
				'template_etcm64e',
				formulario.current,
				'jDVvLa42d83oV_1F9',
			)
			.then(
				(result) => {
					setForm({
						name: '',
						last_name: '',
						phone: '',
						email: '',
						message: '',
					});
					setLoading(false);
					swal({
						title: 'Email sent',
						text: 'Your inquiry has been sent, I will get back to you shortly',
						icon: 'success',
						buttons: 'Understood',
					});
				},
				(error) => {
					setLoading(false);
					swal({
						title: 'Unsent email',
						text: 'The email was not sent, try reloading the page and try again',
						icon: 'error',
						buttons: 'Understood',
					});
				},
			);
	};

	return (
		<main className={styles.container}>
			<div className={styles.content}>
				<h1 className={styles.title}>Fill in the fields and let's connect</h1>
				<form
					action=""
					ref={formulario}
					className={styles.form}
					onSubmit={handleSubmitForm}
				>
					<div className={styles.contentFields}>
						<div className={styles.contentInputAndLabel}>
							{/* <label htmlFor="name" className={styles.label}>
								Name:
							</label> */}
							<input
								type="text"
								name="name"
								value={form.name}
								placeholder="Name..."
								onChange={handleChangeInput}
								className={styles.input}
							/>
						</div>
						<span className={styles.error}>{error.name}</span>
					</div>
					<div className={styles.contentFields}>
						<div className={styles.contentInputAndLabel}>
							{/* <label htmlFor="last_name" className={styles.label}>
								Last name:
							</label> */}
							<input
								type="text"
								value={form.last_name}
								placeholder="Last name..."
								name="last_name"
								onChange={handleChangeInput}
								className={styles.input}
							/>
						</div>
						<span className={styles.error}>{error.last_name}</span>
					</div>
					<div className={styles.contentFields}>
						<div className={styles.contentInputAndLabel}>
							{/* <label htmlFor="phone" className={styles.label}>
								Phone:
							</label> */}
							<input
								type="text"
								name="phone"
								value={form.phone}
								placeholder="Phone..."
								onChange={handleChangeInput}
								className={styles.input}
							/>
						</div>
						<span className={styles.error}>{error.phone}</span>
					</div>
					<div className={styles.contentFields}>
						<div className={styles.contentInputAndLabel}>
							{/* <label htmlFor="email" className={styles.label}>
								Email:
							</label> */}
							<input
								type="text"
								value={form.email}
								placeholder="Email..."
								name="email"
								onChange={handleChangeInput}
								className={styles.input}
							/>
						</div>
						<span className={styles.error}>{error.email}</span>
					</div>
					<div className={styles.contentFields}>
						<div className={styles.contentInputAndText}>
							{/* <label htmlFor="message" className={styles.label}>
								Message:
							</label> */}
							<textarea
								name="message"
								value={form.message}
								placeholder="Message..."
								onChange={handleChangeInput}
								className={styles.textArea}
							/>
						</div>
						<span className={styles.error}>{error.message}</span>
					</div>
					{loading ? (
						<span className={styles.loader}></span>
					) : (
						<button className={styles.button} type="submit">
							Send inquiry
						</button>
					)}
				</form>
			</div>
			<Footer />
		</main>
	);
};

export default ContacteMe;
