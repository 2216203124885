import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useNavigate,useLocation} from 'react-router-dom';
import iconMenu from '../assets/img/menu-2.png';

import styles from '../assets/styles/components/Nav.module.css';
const Nav = () => {

	const { pathname } = useLocation();

	const [viewMenuIcon, setViewMenuIcon] = useState(false);
	const [viewMenu, setViewMenu] = useState(false);
	const navigate = useNavigate();

	const handleView = (event) => {
		event.preventDefault();
		setViewMenu(!viewMenu);
	};

	const handleItemView = (event) => {
		event.preventDefault();
		const page = event.target.name;
		console.log(page)
		setViewMenu(!viewMenu);
		navigate(page);
	};

	useEffect(() => {
		if (window.screen.width <= 576) {
			setViewMenuIcon(true);
		}
	}, []);

	return (
		<div className={styles.container}>
			{!viewMenuIcon ? (
				<div className={styles.content}>
					<ul className={styles.ul}>
						{pathname != "/" && <Link className={styles.item} to={'/'}>
							Start
						</Link>}
						
						<Link className={styles.item} to={'/about'}>
							About
						</Link>
						<Link className={styles.item} to={'/projects'}>
							Projects
						</Link>
						<Link className={styles.item} to={'/tech-kills'}>
							Skills
						</Link>
						<Link className={styles.item} to={'/contact-me'}>
							Contact
						</Link>
					</ul>
				</div>
			) : (
				<div className={styles.contentSubMenu}>
					<img
						src={iconMenu}
						alt=""
						className={styles.iconMenuImage}
						onClick={handleView}
					/>
					{viewMenu && (
						<div className={styles.contentMenuSmall}>
							<ul className={styles.ulMenu}>
							{pathname != "/" && <Link
									className={styles.itemMenu}
									name="/"
									onClick={handleItemView}
									to={'/'}
								>
									Start
								</Link>}
								
								<Link
									className={styles.itemMenu}
									name="/about"
									onClick={handleItemView}
									to={'/about'}
								>
									About
								</Link>
								<Link
									className={styles.itemMenu}
									name="/projects"
									onClick={handleItemView}
									to={'/projects'}
								>
									Projects
								</Link>
								<Link
									className={styles.itemMenu}
									name="/tech-kills"
									onClick={handleItemView}
									to={'/tech-kills'}
								>
									Skills
								</Link>
								<Link
									className={styles.itemMenu}
									name="/contact-me"
									onClick={handleItemView}
									to={'/contact-me'}
								>
									Contact
								</Link>
							</ul>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default Nav;
