import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../assets/styles/components/Footer.module.css';
const Footer = () => {
	return (
		<div className={styles.container}>
			<main className={styles.content}>
				<div className={styles.contentText}>
					<p className={styles.text}> &copy; Developed by</p>
					<Link className={styles.link} to={'/contact-me'}>
						Miguel Fernandez
					</Link>
				</div>
			</main>
		</div>
	);
};

export default Footer;
