import React from 'react';

import Card from '../components/Card';
import styles from '../assets/styles/components/Cards.module.css';
const Cards = () => {
	const projects = [
		{
			id: 1,
			name_project: 'Converter Web',
			description:
				'It is a measurement converter app, which allows you to quickly and accurately obtain the result, it is an intuitive app.',
			picture:
				'https://res.cloudinary.com/dgp4xwknu/image/upload/v1687300253/Portfolio%20Miguel%20Fernandez/ConversorWeb_o6o70r.webp',
			technology: 'HTML5, CSS3, Responsive Desing, JavaScritp',
			deploy: 'https://codepage-conversor-3.netlify.app/',
			repositorio: 'https://github.com/Fer-Mig-Agus/Conversor3.1',
		},
		{
			id: 2,
			name_project: 'Generator QR Web',
			description:
				'It is an app to generate QR code, which allows you to quickly and accurately get the qr of the url loaded, it is an intuitive app for the user.',
			picture:
				'https://res.cloudinary.com/dgp4xwknu/image/upload/v1687300259/Portfolio%20Miguel%20Fernandez/generatorQR_itokc7.webp',
			technology: 'HTML5, CSS3, Responsive Desing, JavaScritp',
			deploy: 'https://generador-qr-3.netlify.app/',
			repositorio: 'https://github.com/Fer-Mig-Agus/Generador-QR',
		},
		{
			id: 3,
			name_project: 'Calculator App',
			description:
				'It is an App based on a conventional calculator, created from scratch',
			picture:
				'https://res.cloudinary.com/dgp4xwknu/image/upload/v1687300249/Portfolio%20Miguel%20Fernandez/calculadora_mdd34w.webp',
			technology: 'HTML5, CSS3, Responsive Desing, JavaScritp',
			deploy: 'https://app-calculator-mf.netlify.app/',
			repositorio: 'https://github.com/Fer-Mig-Agus/CalculatorApp',
		},
		{
			id: 4,
			name_project: 'Balance App',
			description:
				'It is an App on a conventional scale, created from scratch to solve a problem, which involves being able to use the app when there is no electricity in the business, which will accurately calculate the price to charge or the grams to be given to the customer.',
			picture:
				'https://res.cloudinary.com/dgp4xwknu/image/upload/v1687300250/Portfolio%20Miguel%20Fernandez/balanza_miebfp.webp',
			technology: 'HTML5, CSS3, Responsive Desing, JavaScritp',
			deploy: 'https://balanza-app-mf.netlify.app/',
			repositorio: 'https://github.com/Fer-Mig-Agus/BalanzaApp',
		},
		{
			id: 5,
			name_project: 'MF Diseños Web',
			description:
				'This is a web page about graphic design, professional videos and professional photography, thought to promote the work and to be able to communicate with the professional through a form, in which the client leaves the contact data.',
			picture:
				'https://res.cloudinary.com/dgp4xwknu/image/upload/v1687300265/Portfolio%20Miguel%20Fernandez/MFDise%C3%B1os2Web_apti86.webp',
			technology: 'HTML5, CSS3, Responsive Desing, JavaScritp',
			deploy: 'https://md-design-2-miguel-fernandez.netlify.app/',
			repositorio: 'https://github.com/Fer-Mig-Agus/WebMFDisenosV2.0',
		},
		{
			id: 6,
			name_project: 'Doggies App',
			description:
				'This is an App that consumes an API of dogs, which is a very complete app, since it has weight filters, temperaments, sorting, by name. It also allows you to create your own breed of dog, with the corresponding data.',
			picture:
				'https://res.cloudinary.com/dgp4xwknu/image/upload/v1687322473/Portfolio%20Miguel%20Fernandez/Doggies_tqluhc.jpg',
			technology:
				'Node js, Sequelize, Express, PostgreSQL, React, Redux, CSS module, JavaScritp',
			deploy: 'https://pi-dogs-mf.vercel.app/',
			repositorio: 'https://github.com/Fer-Mig-Agus/PI-DOGS',
		},
		{
			id: 7,
			name_project: 'Point Of Sale',
			description:
				'Point of sale program developed in Java, and using MySQL as a database, is a very complete program that I developed for my micro enterprise.',
			picture:
				'https://res.cloudinary.com/dgp4xwknu/image/upload/v1688831923/Portfolio%20Miguel%20Fernandez/Punto_de_venta_TecnoMigui_ptxnro.png',
			technology: 'Java, MySQL',
			deploy: 'https://portfolio-miguel-fernandez.netlify.app/',
			repositorio: 'https://github.com/Fer-Mig-Agus/Punto-De-Vente-TecnoMigui',
		},
		{
			id: 8,
			name_project: 'Sommelier`s Web',
			description:
				'Point of sale program developed in Java, and using MySQL as a database, is a very complete program that I developed for my micro enterprise.',
			picture:
				'https://res.cloudinary.com/dgp4xwknu/image/upload/v1689030967/Portfolio%20Miguel%20Fernandez/Sommeliers_wxyg4r.png',
			technology:
				'Node js, Sequelize, Express, PostgreSQL, React, Redux, CSS module, JavaScritp, Toastify, Tippy, Sweetalert, Clodinary, Stripe, Auth0',
			deploy: 'https://sommeliers-oficial.vercel.app/',
			repositorio: 'https://github.com/Sommelier-s',
		},
		{
			id: 9,
			name_project: 'Rick and Morty Web',
			description:
				'This is a web that consumes the rick and morty API, it is a very complete web, it contains paging, filtering, password recovery, third party authentication, add to favorites. I loved making this page.',
			picture:
				'https://res.cloudinary.com/dgp4xwknu/image/upload/v1689710814/Portfolio%20Miguel%20Fernandez/Rick_and_Morty_bneof6.png',
			technology:
				'Node js, Sequelize, Express, PostgreSQL,Bcryp, JWT, React, Redux, CSS module, JavaScritp',
			deploy: 'https://rickandmorty-mf.vercel.app/',
			repositorio: 'https://github.com/Fer-Mig-Agus/rickAndMortyAPIpage',
		},
		{
			id: 10,
			name_project: 'My Tasks',
			description:
				"My Tasks is an intuitive and efficient task management application that helps you to keep that helps you keep your responsibilities organized and up to date. organized and up to date. Whether it's for your work, studies, personal projects or simply to keep track of your daily tasks, My Tasks My Tasks provides you with the tools you need to keep total control over your you need to maintain total control over your activities.",
			picture:
				'https://res.cloudinary.com/dgp4xwknu/image/upload/v1692895860/Portfolio%20Miguel%20Fernandez/My_Tasks_lugfa7.png',
			technology:
				'Node js, Sequelize, Express, PostgreSQL, React, Redux, CSS module, JavaScritp,JWT, Bcrytp, Sweet alert, Toastify',
			deploy: 'https://my-tasks-mf.vercel.app/',
			repositorio: 'https://github.com/Fer-Mig-Agus/todo_app',
		},
	];

	return (
		<div className={styles.container}>
			{projects.length !== 0 ? (
				projects.map(
					({
						id,
						name_project,
						description,
						picture,
						technology,
						deply,
						repositorio,
					}) => {
						return (
							<Card id={id} key={id} name={name_project} picture={picture} />
						);
					},
				)
			) : (
				<h1 className={styles.titleSecondary}>
					The projects have not yet been uploaded...
				</h1>
			)}
		</div>
	);
};

export default Cards;
