import React from 'react';
import Cards from '../components/Cards';
import styles from '../assets/styles/components/views/Project.module.css';
import Footer from '../components/Footer';
const Project = () => {
	return (
		<div className={styles.container}>
			<main className={styles.content}>
				<h1 className={styles.title}>My Projects</h1>
				<div className={styles.allProjects}>
					<Cards />
				</div>
			</main>
			<Footer/>
		</div>
	);
};

export default Project;
